import React, { useState } from "react";
import "./App.css";  // Importing the CSS file

function App() {
  const [profileUrl, setProfileUrl] = useState("");

  const handleInputChange = (event) => {
    setProfileUrl(event.target.value);
  };

  const handleButtonClick = () => {
    // Trigger the custom protocol with the LinkedIn URL
    window.location.href = `customss://${profileUrl}`;
  };

  return (
    <div className="container">
      <h1>Open LinkedIn Profile</h1>
      <input
        type="text"
        value={profileUrl}
        onChange={handleInputChange}
        placeholder="Enter LinkedIn Profile URL"
      />
      <button onClick={handleButtonClick}>Open Profile</button>
    </div>
  );
}

export default App;
